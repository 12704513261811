import { useNotification } from '@/composable/useNotifications';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import EquipmentTypeApiService from '@/services/api/EquipmentTypeApiService';
import EquipmentType from '@/domain/EquipmentType';
import { getTitleCaseTranslation } from '@/services/TranslationService';

export default class EquipmentTypeService {
    private notification = useNotification();

    private store = masterDataStore.getInstance().configStore;

    private equipmentTypeApiService: EquipmentTypeApiService;

    constructor() {
        this.equipmentTypeApiService = new EquipmentTypeApiService();
    }

    public async addNewEquipmentType(equipmentType: EquipmentType): Promise<boolean> {
        const response = await this.equipmentTypeApiService.addNewEquipmentType(equipmentType);
        if (!response.success) {
            return false;
        }
        const newEquipmentType = new EquipmentType({
            ...equipmentType,
            id: response.data,
        });
        this.store.addEquipmentType(newEquipmentType);
        this.notification.showSuccess(`${getTitleCaseTranslation('core.common.added')} ${equipmentType.description}`);
        return true;
    }

    public async updateEquipmentType(equipmentType: EquipmentType): Promise<boolean> {
        const response = await this.equipmentTypeApiService.updateEquipmentType(equipmentType);
        if (!response.success) {
            return false;
        }
        this.store.updateEquipmentType(equipmentType);
        this.notification.showSuccess(`${getTitleCaseTranslation('core.common.updated')} ${equipmentType.description}`);
        return true;
    }

    public async deleteEquipmentType(equipmentType: EquipmentType): Promise<boolean> {
        const response = await this.equipmentTypeApiService.deleteEquipmentType(equipmentType);
        if (!response.success) {
            return false;
        }
        this.store.deleteEquipmentType(equipmentType);
        this.notification.showSuccess(`${getTitleCaseTranslation('core.common.deleted')} ${equipmentType.description}`);
        return true;
    }
}
