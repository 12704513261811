
import { computed, defineComponent, reactive } from 'vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import { getTitleCaseTranslation } from '../../../../services/TranslationService';
import EquipmentTypeService from '@/services/EquipmentTypeService';
import masterDataStore from '@/modules/master-data/store/MasterDataStore';
import useValidator from '@/validation/useValidator';
import { TableDefinition } from '@/types';
import router from '@/router';
import MasterDataRouteTypes from '@/modules/master-data/routes/types';
import EntityType from '@/domain/enums/EntityTypes';
import EquipmentType from '@/domain/EquipmentType';

type State = {
    equipmentEdit: EquipmentType;
    showForm: boolean;
    modalTitle: string;
    saving: boolean;
};

export default defineComponent({
    name: 'equipment-type-list',
    components: { BSpinner },
    setup() {
        const equipmentTypeService = new EquipmentTypeService();
        const store = masterDataStore.getInstance().configStore;

        const { validateForm, validationResult, clearResults } = useValidator<EquipmentType>('equipment-type');

        const state = reactive<State>({
            showForm: false,
            equipmentEdit: new EquipmentType(),
            modalTitle: '',
            saving: false,
        });

        const table = computed(
            (): TableDefinition<EquipmentType> => ({
                items: store.equipmentTypes,
                key: 'equipmentTypeList',
                name: getTitleCaseTranslation('core.common.equipmentTypes'),
                columnDefinition: [
                    {
                        key: 'description',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.description'),
                    },
                ],
            }),
        );

        function openAdd() {
            clearResults();
            state.equipmentEdit = new EquipmentType();
            state.modalTitle = getTitleCaseTranslation('core.common.newEquipmentType');
            state.showForm = true;
        }

        function openEdit(equipmentType: EquipmentType) {
            clearResults();
            state.equipmentEdit = new EquipmentType(equipmentType);
            state.modalTitle = getTitleCaseTranslation('core.common.editEquipmentType');
            state.showForm = true;
        }

        async function openEquipmentTypeHistory(equipmentType: EquipmentType) {
            await router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                params: { entityType: EntityType.EQUIPMENT_TYPE, entityId: equipmentType.id },
            });
        }

        async function save() {
            validateForm(state.equipmentEdit);

            if (validationResult.isValid) {
                state.saving = true;

                const response = await (state.equipmentEdit.id > 0
                    ? equipmentTypeService.updateEquipmentType(state.equipmentEdit) : equipmentTypeService.addNewEquipmentType(state.equipmentEdit));
                state.showForm = !response;
                state.saving = false;
            }
        }

        return {
            table,
            getTitleCaseTranslation,
            openAdd,
            openEdit,
            openEquipmentTypeHistory,
            save,
            state,
            validationResult,
        };
    },
});
