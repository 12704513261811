<template>
    <div>
        <equipment-type-list />
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import EquipmentTypeList from '@/modules/master-data/views/config/EquipmentTypeList.vue';

export default defineComponent({
    name: 'equipment-type-config',
    components: { EquipmentTypeList },
});
</script>
